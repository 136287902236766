import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';
import Dot from 'core/components/Dot';
import { Option, SimpleOption } from 'core/components/DropDown';
import { User } from 'finance/models/UserPaymentPlan';

const useUserOptionsMapping: (hideDot?: boolean) => (user: User) => SimpleOption = (hideDot) => {
  const { t } = useTranslation();

  return useCallback(
    (user) => {
      const { paymentPlanNames, tags } = user;
      const dotColor = paymentPlanNames?.length ? theme.color.status.green : theme.color.status.red;
      const graphic = hideDot ? undefined : <Dot color={dotColor} />;

      const indications: string[] = [];

      if (user.tags?.length) {
        indications.push(t('Tags: {{tags}}', { tags }));
      }

      indications.push(
        paymentPlanNames?.length
          ? t('Plan(s): {{paymentPlanNames}}', { paymentPlanNames: paymentPlanNames || '-' })
          : t('No Payment Plan')
      );

      return {
        id: user.id,
        name: user.fullName,
        graphic,
        secondary: indications.join(', '),
      } as SimpleOption;
    },
    [t, hideDot]
  );
};

/**
 * Hook for filtering and mapping users for PP assignment
 * @param excludeByPlansId When we add users to PP, we want exclude already added users from the list
 * @param allowedUserIds Only these users will appear in the list
 * @returns  Option[]
 */
export function useUserOptionsReducer(
  excludeByPlansId?: number,
  allowedUserIds?: number[],
  hideDot?: boolean
): (users: User[]) => Option[] {
  const userOptionsMapping = useUserOptionsMapping(hideDot);

  return useCallback(
    (users) => {
      return users.reduce((acc: Option[], it: User) => {
        const userIsAllowed = allowedUserIds ? allowedUserIds.includes(it.id) : true;
        const usersPlanAllowed = excludeByPlansId
          ? !(it.paymentPlanIds || []).includes(excludeByPlansId)
          : true;

        if (userIsAllowed && usersPlanAllowed) {
          acc.push(userOptionsMapping(it));
        }

        return acc;
      }, []);
    },
    [excludeByPlansId, userOptionsMapping, allowedUserIds]
  );
}

export default useUserOptionsMapping;
