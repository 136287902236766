import { useFormikContext } from 'formik';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RolesId } from 'app/models/Role';
import User from 'app/models/User';
import DropDown, { Option, SimpleOption } from 'core/components/DropDown';
import Modal from 'core/components/Modal';
import { transformDateToString } from 'core/effects/apiCall/dateTimeTransformations';
import { stringify } from 'core/functions/qs';
import useAppSelector from 'core/hooks/useAppSelector';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useResources } from 'core/hooks/useResource';
import AvailabilityCategory from 'personnel/models/AvailabilityCategory';
import useOfficersReducer from 'planning/hooks/useOfficersReducer';
import { TeamMemberStatus } from 'planning/models/TeamMember';

import { TeamMemberType } from '../../enums';
import { TeamFormData, TeamMemberData } from '../../useTeamInputMapping';

interface Props {
  type: TeamMemberType;
  open: boolean;
  onClose: () => void;
  notRemovableUsersId?: number[];
}

const emptyData = [] as TeamMemberData[];

const AddModal: FC<Props> = ({ type, open, onClose, notRemovableUsersId }) => {
  const { t } = useTranslation();
  const { data: availabilityCategories, reload: loadAvailabilityCategories } =
    useResources<AvailabilityCategory>('profile/availabilities/categories', { autoload: false });
  const availabilitiesEnabled = useBoolClientOption('enableAvailabilities');

  const formikContext = useFormikContext<TeamFormData & { dateRange?: { from: Date; to: Date } }>();
  const invitedMembers = formikContext.values.invitedMembers || emptyData;
  const assignedMembers = formikContext.values.assignedMembers || emptyData;
  const dateRange = formikContext.values.dateRange;

  const [selected, setSelected] = useState<number[]>([]);

  useEffect(() => {
    if (!open) return;

    // Load categories if they are available
    if (availabilitiesEnabled) {
      loadAvailabilityCategories(false);
    }

    // Preselect currently filled members
    setSelected(
      type === TeamMemberType.ASSIGNED
        ? assignedMembers.map((m) => m.usersId)
        : invitedMembers.map((m) => m.usersId)
    );

    // We don't want to change the selection outside of opening
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const selectWithoutRemovingNotRemovableUsers = useCallback(
    (selection: number[]) => {
      if (notRemovableUsersId && notRemovableUsersId.some((it) => selection.indexOf(it) < 0)) {
        return;
      }
      setSelected(selection);
    },
    [notRemovableUsersId]
  );

  const optionsReducer = useOfficersReducer(
    availabilityCategories,
    useMemo(
      () => ({
        beforeReducer: (users) => {
          const alreadyAssigned = new Set(assignedMembers.map((m) => m.usersId));
          return users.filter(
            (user) =>
              type === TeamMemberType.ASSIGNED ||
              !alreadyAssigned.has(user.id) ||
              user?.accreditations?.length! > 0
          );
        },
        afterReducer: (options) =>
          options.sort((b: Option, a: Option) =>
            typeof a !== 'string' && typeof b !== 'string' && b.name
              ? b.name.localeCompare(a.name, undefined, { sensitivity: 'variant' })
              : 0
          ),
      }),
      [assignedMembers, type]
    )
  );

  const clientId = useAppSelector(
    (state) => (state.core.user && state.core.user.client.id) || null
  );
  const availabilityConditions = dateRange
    ? {
        availableFrom: transformDateToString(dateRange.from, 'DATE'),
        availableTo: transformDateToString(dateRange.to, 'DATE'),
      }
    : {};
  const { data: users } = useResources<User>(
    `users?${stringify({
      active: true,
      rolesId: RolesId.DCO,
      clientsId: clientId,
      ...availabilityConditions,
    })}`,
    { autoload: !!open }
  );
  const getOptions = () => (users ? optionsReducer(users, selected) : []);
  const options = useMemo<Option[]>(getOptions, [optionsReducer, selected, users]);

  const add = useCallback(
    (userIds: number[]) => {
      if (!Array.isArray(userIds) || userIds.length === 0) return;
      const selection = new Set(userIds);

      const alreadySelected = new Set(
        (type === TeamMemberType.ASSIGNED ? assignedMembers : invitedMembers).map((m) => m.usersId)
      );

      const newMembers = options!
        .filter(
          (o) =>
            o !== 'SEPARATOR' &&
            'extra' in o &&
            userIds.includes(o.id as number) &&
            !alreadySelected.has(o.id as number)
        )
        .map((o) => {
          const filteredOption = o as SimpleOption;
          const user = filteredOption.extra as User;

          const status =
            type === TeamMemberType.INVITED
              ? TeamMemberStatus.SELECTED
              : TeamMemberStatus.CONFIRMED;

          return {
            usersId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            fullName: user.fullName!,
            avatarStorageKey: user.avatarStorageKey!,
            status,
            internalComment: null,
            statement: null,
            invitations: [],
            assignmentNotifications: [],
            accreditations: [],
            availabilities: user.availabilities,
            tags: user.tags || [],
            alreadySavedRoles: null,
            roles: [],
            allowedRoles: (user.accreditations || [])
              .filter((it) => !!it.role)
              .map((it) => it.role),
          };
        });

      const newMemberIds = new Set(newMembers.map((m) => m.usersId));

      if (type === TeamMemberType.INVITED) {
        formikContext.setValues({
          ...formikContext.values,
          invitedMembers: [
            ...invitedMembers.filter((m) => selection.has(m.usersId)),
            ...newMembers,
          ],
        });
      } else {
        formikContext.setValues({
          ...formikContext.values,
          invitedMembers: formikContext.values.invitedMembers.filter(
            (m) => !newMemberIds.has(m.usersId)
          ),
          assignedMembers: [
            ...assignedMembers.filter((m) => selection.has(m.usersId)),
            ...newMembers,
          ],
        });
      }
    },
    [assignedMembers, invitedMembers, options, formikContext, type]
  );

  return (
    <Modal
      ariaLabel={t('New Team Member dropdown dialog')}
      open={open}
      onClose={() => {
        setSelected([]);
        onClose();
      }}
      onConfirm={() => add(selected)}
      onClear={() => setSelected([])}
    >
      <DropDown
        mode="inline"
        value={selected}
        onChange={(selection) => selectWithoutRemovingNotRemovableUsers(selection as number[])}
        options={options || []}
        loading={!options}
      />
    </Modal>
  );
};

export default AddModal;
