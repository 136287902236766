import { ColumnApi, GridApi, RowClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { followCursor } from 'tippy.js';

import './styles.scss';

import ContextMenu from 'core/components/ContextMenu';
import { parse } from 'core/functions/qs';
import BaseModel from 'core/models/Base';

import LoadingCellRenderer from './CellRenderers/LoadingCellRenderer';
import EmptyOverlay from './EmptyOverlay';
import {
  AvatarCellRenderer,
  BadgeCellRenderer,
  CircleLettersCellRenderer,
  EllipsisCellRenderer,
  IconCellRenderer,
  StatusCellRenderer,
} from './FastCellRenderers';
import FiltersToolbar from './FiltersToolbar';
import { onCopyableRowClicked, showContextMenuOnlyIfLink } from './helpers';
import useBulkActions from './hooks/useBulkActions';
import useColumnConfiguration from './hooks/useColumnConfiguration';
import useColumnDefs from './hooks/useColumnDefs';
import useComponentUpdates from './hooks/useComponentUpdates';
import useContextMenu from './hooks/useContextMenu';
import useDataSource from './hooks/useDataSource';
import { useDefaultFilterValues } from './hooks/useDefaultGridConfiguration';
import useDefaultViewConfiguration from './hooks/useDefaultViewConfiguration';
import useDuplicateRowsHighlight from './hooks/useDuplicateRowsHighlight';
import useGridChanges from './hooks/useGridChanges';
import useRedirectActions from './hooks/useRedirectActions';
import useUrlState from './hooks/useUrlState';
import { Props, SelectedRows } from './props';
import { AgGridWrapper, GridOverlayContainer, GridWrapper } from './styled';
import Toolbar from './Toolbar';

export type {
  BulkAction,
  CustomFilter,
  DataGridApi,
  DropDownFilter,
  Filter,
  FilterDelimiter,
  FilterValue,
  Props,
  RenderedCustomFilter,
  ResourceDropDownFilter,
  RowAction,
} from './props';

function DataGrid<T extends BaseModel>({
  endpoint,
  bulkActions,
  filters,
  agGridProps,
  customSettings,
  ...props
}: Props<T>): ReactElement<Props<T>> {
  const [selectedRows, setSelectedRows] = useState<SelectedRows>([]);
  const [printMode, setPrintMode] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const agColumnApiRef = useRef<ColumnApi>();
  const agGridApiRef = useRef<GridApi>();
  const { search } = useLocation();

  const { updateDuplicateRows, hasDuplicate } = useDuplicateRowsHighlight(
    agGridApiRef,
    props.highlightDuplicateRowsBy
  );
  // Ugly workaround as ag-grid wouldn't use the new getRowClass properly :(
  const highlightRef = useRef(false);
  const highlightDuplicates: boolean = useMemo(() => {
    const params = parse(search.replace('?', ''));
    return 'duplicity' in params && params.duplicity === 'true';
  }, [search]);
  useEffect(() => {
    highlightRef.current = highlightDuplicates || false;
  }, [highlightDuplicates]);
  const getRowClass = useCallback(
    ({ node }: any) => {
      if (!node?.id || !highlightRef.current) return '';
      return hasDuplicate(node.id) ? 'ag-row-highlighted' : '';
    },
    [hasDuplicate]
  );

  const defaultFilterValues = useDefaultFilterValues(filters);

  const { loading, counts, getDataSource } = useDataSource(
    endpoint,
    selectedRows,
    updateDuplicateRows,
    agGridApiRef,
    defaultFilterValues,
    props.defaultFilters,
    props.rowsReducer,
    props.onDataReceived
  );

  const {
    filterValues,
    setFilterAndColumnValues,
    saveSortState,
    sortState,
    reloadData,
    handleSort,
  } = useUrlState(
    setSelectedRows,
    getDataSource,
    agGridApiRef,
    agColumnApiRef,
    defaultFilterValues,
    !!props.disableViews,
    filters
  );

  useDefaultViewConfiguration(
    !!props.disableViews,
    endpoint,
    props.columnDefs,
    setFilterAndColumnValues,
    filterValues,
    filters
  );

  const initialDataSource = useMemo(
    () => ({ getRows: () => [] }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { onGridReady, onFirstDataRendered, disableSorting, onContainerRefChange } = useGridChanges(
    agGridApiRef,
    agColumnApiRef,
    filterValues,
    defaultFilterValues,
    reloadData,
    !!props.disableViews,
    selectedRows,
    filters,
    props.defaultFilters,
    props.apiRef
  );

  const setContainerRef = useCallback(
    (node: HTMLDivElement) => {
      containerRef.current = node;
      if (node) {
        onContainerRefChange(node);
      }
    },
    [onContainerRefChange]
  );

  const { handleBulkActionSuccess, availableBulkActions, handleRowSelected } = useBulkActions(
    setSelectedRows,
    reloadData,
    agGridApiRef,
    bulkActions
  );

  const {
    columnConfiguration,
    handleColumnMoved,
    handleColumnPinned,
    handleColumnResized,
    handleColumnVisible,
  } = useColumnConfiguration();

  const { preparedColumnDefs, confirmationDialog } = useColumnDefs<T>(
    props.columnDefs,
    columnConfiguration,
    reloadData,
    handleSort,
    props.rowActions,
    props.statusColumn,
    availableBulkActions,
    disableSorting,
    sortState,
    printMode
  );

  const {
    items: contextMenuItems,
    onCellContextMenu,
    visible: contextMenuVisible,
    hide: hideContextMenu,
  } = useContextMenu<T>(props.columnDefs, handleSort, props.statusColumn, disableSorting);

  useComponentUpdates(
    reloadData,
    agGridApiRef,
    agColumnApiRef,
    bulkActions,
    highlightDuplicates,
    props.defaultFilters
  );

  const { getDetailUrl, onRowClicked } = useRedirectActions({
    entity: props.entity,
    getDetailUrl: props.getDetailUrl,
    onRowClicked: props.onRowClicked,
  });

  const empty = !agGridApiRef.current || agGridApiRef.current.getDisplayedRowCount() === 0;

  useEffect(() => {
    agGridApiRef.current?.sizeColumnsToFit();
  }, [preparedColumnDefs]);

  useEffect(() => {
    let beforePrint = function () {
      if (agGridApiRef.current) {
        setPrintMode(true);
        agGridApiRef.current.setDomLayout('print');
      }
    };

    let afterPrint = function () {
      if (agGridApiRef.current) {
        setPrintMode(false);
        agGridApiRef.current.setDomLayout('normal');
        // We need to refresh columns manually
        // once column definitions is changed (hide checkboxes and actions in print),
        // data-grid provides some re-render but its broken
        // and we have to trigger one more repaint manually
        setTimeout(() => {
          agGridApiRef.current?.sizeColumnsToFit();
        }, 1000);
      }
    };

    if (window.matchMedia) {
      const mql = window.matchMedia('print');

      const handler = (e: MediaQueryListEvent) => {
        if (e.matches) {
          beforePrint();
        } else {
          afterPrint();
        }
      };

      if ('addEventListener' in mql && typeof mql.addEventListener === 'function') {
        mql.addEventListener('change', handler);
      } else {
        mql.addListener(handler);
      }
    }

    window.onbeforeprint = beforePrint;
    window.onafterprint = afterPrint;
  }, []);

  return (
    <>
      <GridWrapper toolbarHeight={props.customToolbarHeight}>
        {props.customToolbar ? (
          props.customToolbar
        ) : props.disableViews ? (
          <FiltersToolbar
            bulkActions={availableBulkActions}
            getSelectedNodes={() =>
              agGridApiRef.current ? agGridApiRef.current.getSelectedNodes() : []
            }
            defaultFilterValues={defaultFilterValues}
            onBulkSuccess={handleBulkActionSuccess}
            selectedRows={selectedRows}
            filterValues={filterValues}
            onFiltersAndColumnsChange={setFilterAndColumnValues}
            filters={filters}
            counts={counts}
            disableTotalCount={props.disableTotalCount}
            customToolbarElement={props.customToolbarElement}
          />
        ) : (
          <Toolbar
            bulkActions={availableBulkActions}
            getSelectedNodes={() =>
              agGridApiRef.current ? agGridApiRef.current.getSelectedNodes() : []
            }
            defaultFilterValues={defaultFilterValues}
            onBulkSuccess={handleBulkActionSuccess}
            selectedRows={selectedRows}
            filterValues={filterValues}
            onFiltersAndColumnsChange={setFilterAndColumnValues}
            filters={filters}
            columnDefs={preparedColumnDefs}
            originalColumnDefs={props.columnDefs}
            endpoint={endpoint}
            highlightDuplicateRowsBy={props.highlightDuplicateRowsBy}
            columnConfiguration={columnConfiguration}
            counts={counts}
            disableTotalCount={props.disableTotalCount}
            customToolbarElement={props.customToolbarElement}
            customSettings={customSettings}
            disableSettings={props.disableSettings}
          />
        )}
        <ContextMenu
          menuId="ag-grid-context-menu"
          menuItems={contextMenuItems}
          visible={contextMenuVisible}
          followCursor="initial"
          plugins={[followCursor]}
          onClickOutside={() => hideContextMenu()}
          onToggle={() => hideContextMenu()}
        >
          <AgGridWrapper
            ref={setContainerRef}
            className="ag-theme-material"
            id="datagrid"
            // Prevent showing the default browser context menu when user right-clicks somewhere in grid
            onContextMenu={(e) => showContextMenuOnlyIfLink(e, 'datagrid')}
            onClick={() => hideContextMenu()}
          >
            <GridOverlayContainer>
              {loading && <LoadingCellRenderer />}
              {!loading && empty && <EmptyOverlay />}
            </GridOverlayContainer>
            <AgGridReact<T>
              onCellContextMenu={(e) => onCellContextMenu(e, getDetailUrl)}
              onViewportChanged={() => {
                if (!loading) {
                  containerRef.current?.classList.add('rendered');
                }
              }}
              copyHeadersToClipboard={true}
              columnDefs={preparedColumnDefs}
              onRowSelected={availableBulkActions ? handleRowSelected : undefined}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              isFullWidthRow={({ rowNode }) => !rowNode.data?.id}
              fullWidthCellRenderer={LoadingCellRenderer}
              onRowClicked={
                onRowClicked
                  ? (e: RowClickedEvent<T>) => onCopyableRowClicked(e, (e) => onRowClicked(e.data))
                  : undefined
              }
              getRowId={({ data }) => data?.id || 'false'}
              getRowClass={getRowClass}
              rowModelType="infinite"
              datasource={initialDataSource}
              onSortChanged={saveSortState}
              onColumnVisible={handleColumnVisible}
              onColumnPinned={handleColumnPinned}
              onColumnResized={handleColumnResized}
              onColumnMoved={handleColumnMoved}
              suppressMultiSort
              suppressRowClickSelection
              enableCellTextSelection
              ensureDomOrder
              rowMultiSelectWithClick={false}
              suppressCellFocus
              tooltipShowDelay={500}
              rowSelection="multiple"
              isRowSelectable={({ data }) => !!data?.id}
              suppressRowDeselection
              tooltipMouseTrack={false}
              components={{
                iconCellRenderer: IconCellRenderer,
                badgeCellRenderer: BadgeCellRenderer,
                avatarCellRenderer: AvatarCellRenderer,
                ellipsisCellRenderer: EllipsisCellRenderer,
                statusCellRenderer: StatusCellRenderer,
                circleLettersCellRenderer: CircleLettersCellRenderer,
                ...props.customFastCellRenderers,
              }}
              frameworkComponents={{
                ...props.customCellRenderers,
              }}
              {...(agGridProps || {})}
            />
          </AgGridWrapper>
        </ContextMenu>
      </GridWrapper>
      {confirmationDialog}
    </>
  );
}

export default DataGrid;
