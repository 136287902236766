import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { favoriteView } from 'core/actions';
import ContextMenu from 'core/components/ContextMenu';
import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import IconButton from 'core/components/IconButton';
import Modal from 'core/components/Modal';
import { Tab } from 'core/components/Tabs/styled';
import { COLUMN_KEYS } from 'core/containers/DataGrid/hooks/useColumnConfiguration';
import useAppSelector from 'core/hooks/useAppSelector';
import { UserView } from 'core/models/UserView';

import { StyledTabBar } from '../styled';
import useViewTabs, { ViewTabs } from '../useViewTabs';

import { NoMarginCell, Title } from './styled';

export const VIEW_URL_KEYS = [...COLUMN_KEYS, 'activeView', 'duplicity'];

interface Props {
  activeView: UserView | null;
  activeTab: ViewTabs;
  setActiveTab: (newActiveTab: ViewTabs) => void;
  disableSettings: boolean;
  onDelete: (id: number) => void;
  onClone: () => void;
  reloadUserViews: () => void;
  onOpenAllViews: () => void;
}

const Header: FC<Props> = ({
  activeView,
  setActiveTab,
  activeTab,
  disableSettings,
  onDelete,
  onClone,
  reloadUserViews,
  onOpenAllViews,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tabs = useViewTabs(disableSettings);
  const isFormTabActive = activeTab === ViewTabs.DETAILS;
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const { savingInProgress, deletingInProgress, usersId } = useAppSelector(({ core }) => ({
    savingInProgress: core.viewSavingInProgress,
    deletingInProgress: core.viewDeletingInProgress,
    usersId: core.user!.id,
  }));

  const moreActions = useMemo(() => {
    return [
      {
        key: 'clone',
        text: t('Clone'),
        onClick: () => onClone(),
        disabled: savingInProgress || deletingInProgress,
      },
      {
        key: 'delete',
        text: t('Delete'),
        onClick: () => setConfirmDelete(true),
        disabled: savingInProgress || usersId !== activeView?.createdBy,
      },
    ];
  }, [t, savingInProgress, deletingInProgress, onClone, usersId, activeView]);

  return (
    <>
      <FlexRow>
        <FlexCell flex={3}>
          <Title>
            {activeView
              ? t('Customize: {{viewName}}', { viewName: activeView.name })
              : t('Create View')}
          </Title>
        </FlexCell>

        <NoMarginCell>
          <IconButton
            icon="save"
            tooltip={savingInProgress ? t('Saving...') : t('Save')}
            disabled={savingInProgress}
            onClick={isFormTabActive ? undefined : () => setActiveTab(ViewTabs.DETAILS)}
            type={isFormTabActive ? 'submit' : 'button'}
            form={isFormTabActive ? 'viewForm' : undefined}
          />
        </NoMarginCell>

        {activeView && !!activeView.default && (
          <NoMarginCell>
            <IconButton icon="add" tooltip={t('New')} onClick={onClone} />
          </NoMarginCell>
        )}

        <>
          {activeView && !activeView.default && (
            <NoMarginCell>
              <IconButton
                icon={activeView.favorite ? 'star' : 'star_outline'}
                tooltip={t('Favorites')}
                onClick={() => {
                  dispatch(
                    favoriteView(activeView.id, !Boolean(activeView.favorite), () =>
                      reloadUserViews()
                    )
                  );
                }}
              />
            </NoMarginCell>
          )}

          <NoMarginCell>
            <IconButton icon="storage" tooltip={t('All Views')} onClick={onOpenAllViews} />
          </NoMarginCell>

          {activeView && (
            <NoMarginCell>
              <ContextMenu menuItems={moreActions} menuId="viewOptions">
                <IconButton icon="more_horiz" tooltip={t('More')} />
              </ContextMenu>
            </NoMarginCell>
          )}
        </>
      </FlexRow>

      <StyledTabBar>
        {tabs.map((it) => (
          <Tab
            active={activeTab === it.type}
            onClick={() => setActiveTab(it.type)}
            key={it.key}
            type="button"
            disabled={it.disabled}
          >
            <span>{it.title}</span>
          </Tab>
        ))}
      </StyledTabBar>

      <Modal
        ariaLabel={t('Deletion Confirmation Dialog')}
        onClose={() => setConfirmDelete(false)}
        title={t('Delete Selected View')}
        onConfirm={() => confirmDelete && activeView && onDelete(activeView.id)}
        open={confirmDelete}
      >
        {t('Are you sure you want to permanently delete this View?')}
      </Modal>
    </>
  );
};

export default Header;
