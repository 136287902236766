import { isNumber } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { StatusColor } from 'app/theme';
import { Option } from 'core/components/DropDown';
import { DCOInvoiceStatus } from 'finance/models/DCOInvoice';

export const useGetInvoiceStatusColor =
  () =>
  (entity?: { status: DCOInvoiceStatus }): StatusColor => {
    switch (entity?.status) {
      case DCOInvoiceStatus.DRAFT:
        return 'orange';

      case DCOInvoiceStatus.SUBMITTED:
        return 'cyan';

      case DCOInvoiceStatus.ON_HOLD:
        return 'gray';

      case DCOInvoiceStatus.DECLINED:
        return 'red';

      case DCOInvoiceStatus.OUT_FOR_APPROVAL:
        return 'blue';

      case DCOInvoiceStatus.ACCEPTED:
        return 'green';

      case DCOInvoiceStatus.PAID:
        return 'black';

      default:
        return 'orange';
    }
  };

export const useGetInvoiceStatusName = () => {
  const { t } = useTranslation();

  return useCallback(
    (entity?: { status: DCOInvoiceStatus }) => {
      switch (entity?.status) {
        case DCOInvoiceStatus.SUBMITTED:
          return t('Submitted');

        case DCOInvoiceStatus.ON_HOLD:
          return t('On Hold');

        case DCOInvoiceStatus.DECLINED:
          return t('Declined');

        case DCOInvoiceStatus.OUT_FOR_APPROVAL:
          return t('Out For Approval');

        case DCOInvoiceStatus.ACCEPTED:
          return t('Approved');

        case DCOInvoiceStatus.PAID:
          return t('Paid');

        default:
          return t('Draft');
      }
    },
    [t]
  );
};

export const useGetInvoiceStatusIcon =
  () =>
  (status?: DCOInvoiceStatus): string => {
    switch (status) {
      case DCOInvoiceStatus.DRAFT:
        return 'edit';

      case DCOInvoiceStatus.SUBMITTED:
        return 'arrow_up_circle';

      case DCOInvoiceStatus.ON_HOLD:
        return 'error_outline';

      case DCOInvoiceStatus.DECLINED:
        return 'cancel_circle';

      case DCOInvoiceStatus.OUT_FOR_APPROVAL:
        return 'person_circle';

      case DCOInvoiceStatus.ACCEPTED:
        return 'checkmark_circle';

      case DCOInvoiceStatus.PAID:
        return 'paid';

      default:
        return 'edit';
    }
  };

export const useInvoiceStatusOptions = (): Option[] => {
  const getStatusName = useGetInvoiceStatusName();

  return useMemo(() => {
    const statusIds = Object.values(DCOInvoiceStatus).filter((x) =>
      isNumber(x)
    ) as DCOInvoiceStatus[];

    return statusIds.map((statusId) => ({
      id: statusId,
      name: getStatusName({ status: statusId }),
    }));
  }, [getStatusName]);
};
